<template>
  <component :is="$route.meta.layout">
    <v-card>
      <v-card-title primary-title>
        Add a new Host
      </v-card-title>

      <PresenterForm
        @form-cancel="cancelCreation"
        @form-submit="submitPresenter"
      >
        <template v-slot:cancelButtonText>
          Cancel
        </template>

        <template v-slot:confirmButtonText>
          Add Host
        </template>
      </PresenterForm>
    </v-card>
  </component>
</template>

<script>
import { mapActions } from 'vuex';
import scoped from '../../helpers/scoped';
import PresenterForm from '../../components/forms/PresenterForm';

export default {
  components: {
    PresenterForm,
  },

  mixins: [scoped],

  methods: {
    ...mapActions('users', ['createUser']),

    cancelCreation() {
      this.$router.push({ name: 'PresenterIndex' });
    },

    async submitPresenter(presenterData) {
      try {
        await this.createUser({
          ...presenterData,
          scopeId: this.defaultScope,
        });
        this.$router.push({ name: 'PresenterIndex' });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
