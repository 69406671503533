<template>
  <component :is="$route.meta.layout">
    <v-card>
      <v-card-title primary-title>
        Update a Host
      </v-card-title>

      <PresenterForm
        :presenter-data="formData"
        @form-cancel="cancelUpdate"
        @form-submit="submitPresenter"
      >
        <template v-slot:cancelButtonText>
          Cancel
        </template>
        <template v-slot:confirmButtonText>
          Update Host
        </template>
      </PresenterForm>
    </v-card>
  </component>
</template>

<script>
import { mapActions } from 'vuex';
import PresenterForm from '../../components/forms/PresenterForm';

export default {
  components: {
    PresenterForm,
  },

  props: {
    presenterId: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    formData: {
      firstName: '',
      lastName: '',
      subtitle: '',
    },
  }),

  async created() {
    if (this.pristine) await this.fetchRooms();
    this.fetchUser(this.presenterId)
      .then(({ firstName, lastName, subtitle }) => {
        this.formData = {
          firstName,
          lastName,
          subtitle,
        };
        this.$forceUpdate();
      })
      .catch((err) => console.error(err));
  },

  methods: {
    ...mapActions('users', ['fetchUser', 'updateUser']),

    cancelUpdate() {
      this.$router.push({ name: 'PresenterIndex' });
    },

    submitPresenter(presenterData) {
      this.updateUser({
        id: this.presenterId,
        ...presenterData,
      })
        .then(() => this.$router.push({ name: 'PresenterIndex' }))
        .catch((err) => console.error(err));
    },
  },
};
</script>
