<template>
  <component :is="$route.meta.layout">
    <v-card v-if="pristine">
      <v-skeleton-loader
        :loading="pristine"
        type="table"
      />
    </v-card>
    <v-card v-else>
      <v-card-title>
        Registered Hosts
        <v-spacer />
        <v-text-field
          v-model="customParams.firstName"
          append-icon="mdi-magnify"
          name="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="filteredUsers"
        :server-items-length="total"
        :footer-props="{
          itemsPerPageOptions: [15, 30, 50, -1],
        }"
        :items-per-page="pagination.take"
        @pagination="updatePagination"
        @update:items-per-page="updateTake"
        @update:options="updateSort"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-autocomplete
              :value="defaultScope"
              chips
              auto-select-first
              :items="multiselectScopes"
              item-value="scopeId"
              item-text="name"
              label="Select a Scope to use."
              @change="scopeChanged"
            />
            <v-spacer />
            <v-btn
              color="primary"
              small
              class="mb-2"
              @click="navigateToCreate"
            >
              New Host
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr
            class="tw-cursor-pointer"
            @click="navigateToUpdate(item.userId)"
          >
            <td class="tw-w-10">
              <img
                :src="item.avatar || `https://avatars.dicebear.com/api/identicon/${item.createdAt}.svg`"
                :alt="`${item.firstName} ${item.lastName}`"
                class="tw-rounded-full tw-w-8 tw-h-8 shadow"
              >
            </td>
            <td>{{ `${item.firstName} ${item.lastName}` }}</td>
            <td>
              <v-icon
                small
                class="mr-2 hover:tw-text-yellow-500"
                @click="navigateToUpdate(item.userId)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                class="hover:tw-text-red-500"
                @click.stop="selectItem(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <ConfirmDialog :active="dialog">
      <template v-slot:title>
        Are you sure?
      </template>

      <template v-slot:text>
        <p>
          This action will delete the Host
          "<span
            class="tw-font-bold"
            v-text="`${selected.firstName} ${selected.lastName}`"
          />".
        </p>
        <p class="tw-text-red-500">
          This action cannot be undone.
        </p>
      </template>

      <template v-slot:cancel>
        <v-btn
          small
          color="primary"
          @click="selectItem()"
        >
          Cancel
        </v-btn>
      </template>
      <template v-slot:confirm>
        <v-btn
          small
          color="error"
          @click="removeUser(selected.userId)"
        >
          Confirm
        </v-btn>
      </template>
    </ConfirmDialog>
  </component>
</template>

<script>
import { includes, debounce } from 'lodash';
import { mapMutations, mapState, mapActions } from 'vuex';
import ConfirmDialog from '../../components/ConfirmDialog';
import confirms from '../../helpers/confirms';
import scoped from '../../helpers/scoped';
import pagination from '../../helpers/pagination';

export default {
  components: {
    ConfirmDialog,
  },

  mixins: [scoped, pagination, confirms],

  data: () => ({
    search: '',
    headers: [
      { text: 'Avatar', sortable: false, value: 'avatar' },
      { text: 'Nickname', sortable: true, value: 'nickname' },
      { text: 'Actions', sortable: false, value: 'actions' },
    ],
  }),

  computed: {
    ...mapState('users', ['pristine', 'users', 'total']),

    filteredUsers() {
      if (!this.users.length) return [];
      return this.users.filter(
        (user) => includes(`${user.firstName} ${user.lastName}`.toLowerCase(), this.search.toLowerCase()),
      );
    },

    computedWatch() {
      return [this.computedParams, this.defaultScope];
    },
  },

  watch: {
    computedWatch: {
      deep: true,
      immediate: true,
      // eslint-disable-next-line func-names
      handler: debounce(function () {
        if (this.defaultScope) {
          this.fetchUsers({
            ...this.computedParams,
            scopeId: this.defaultScope,
          });
        }
      }, 250),
    },
  },

  methods: {
    ...mapMutations([
      'toggleLoading',
    ]),

    ...mapActions('users', [
      'fetchUsers',
      'deleteUser',
    ]),

    navigateToCreate() {
      this.$router.push({ name: 'PresenterCreate' });
    },

    navigateToUpdate(presenterId) {
      this.$router.push({ name: 'PresenterUpdate', params: { presenterId } });
    },

    async removeUser(presenterId) {
      this.selectItem();
      await this.deleteUser(presenterId);
    },
  },
};
</script>
